@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

html {
  scroll-behavior: smooth;
}

svg {
  height: 100%;
  width: 100%;
}
linearGradient {
  width: 100%;
}

/* .tilt {
  background-image: url("/background-tilt.png");
} */
